<template>
  <div>
    <v-row align="center" justify="center" style="margin:0" >
      <v-col cols="12" class="text-center">
        <div>
        <img
            style="margin-top:5px"
            src="/logoapuntoga.png"
            alt="Menu"
            height="100px"
        >
      </div>
      </v-col>

      <v-col cols="12" >
        <template>
          <v-form ref="form" lazy-validation @submit.prevent="login">
            <v-col cols="12">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="mx-auto"
                  max-width="430"
                  :elevation="hover ? 8 : 2"
                >
                  <v-card-title style="background-color: #fafafa">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="title"
                          >Inicio de sesión</v-list-item-title
                        >
                        <v-list-item-subtitle class="subtitle-1"
                          ><small>Ingrese sus credenciales</small>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-spacer></v-spacer>
                      <v-icon x-large>mdi-account</v-icon>
                    </v-list-item>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" xs="12">
                          <v-text-field
                            label="Cuenta (*)"
                            required
                            :counter="30"
                            maxlength="30"
                            id="email"
                            v-model="usuarioDto.usuario"
                            :rules="[
                              (v) => !!v || 'Cuenta es requerida',
                              (v) =>
                                (v && v.length <= 30 && v.length >= 5) ||
                                'La Cuenta debe contener al menos 5 caracteres',
                            ]"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" xs="12">
                          <v-text-field
                            id="password"
                            label="Password (*)"
                            :append-icon="
                              mostrarPass ? 'mdi-eye-off' : 'mdi-eye'
                            "
                            @click:append="() => (mostrarPass = !mostrarPass)"
                            :type="mostrarPass ? 'password' : 'text'"
                            required
                            :counter="30"
                            maxlength="30"
                            v-model="usuarioDto.clave"
                            autocomplete="off"
                            :rules="[
                              (v) => !!v || 'Password es requerido',
                              (v) =>
                                (v && v.length <= 30 && v.length >= 6) ||
                                'El Password debe ser de 6 a 30 caracteres',
                            ]"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn type="submit" color="primary" class="pl-4 pr-4">
                      Ingresar
                      <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="primary"
                        style="margin-left: 10px"
                      ></v-progress-circular>
                      <v-icon left>mdi-login</v-icon>
                    </v-btn>
                  </v-card-actions>
                  <br />
                  <v-divider></v-divider>
                  <div class="pa-1 text-center"></div>

                  <v-card-text class="text-center text--white">
                    2022 — <strong>Apunto</strong><br />
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-form>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from "@/store/index";
export default {
  data: () => ({
    mostrarPass: String,
    loading: false,
    usuarioDto: {
      usuario: "",
      clave: "",
    },
  }),
  mounted() {},
  methods: {
    login() {
      //this.$router.push({name:'Home'});
      //this.$toastr.success('Bienvenido al sistema');
      // store.dispatch('getToken', {
      //   email: this.email,
      //   password: this.password
      // }).then(response => {
      //   if(response.data.res){
      //     this.$toastr.success(response.data.message);
      //     this.$router.push({name:'Home'});
      //   }
      //   else{
      //     this.$toastr.error(response.data.message);
      //   }
      // })

      store.dispatch("retrieveToken", this.usuarioDto).then(
        (response) => {
          if (response) {
            this.loading = false;
            //              this.$router.go()
            this.$router.push({ name: "Servicios" });
          } else {
            this.loading = false;
            this.$store.commit("alert", {
              color: "warning",
              text: "Usuario o contraseña incorrecto",
            });
          }
        },
        (reject) => {
          if (reject) this.loading = false;
          this.$store.commit("alert", {
            color: "warning",
            text: "Usuario o contraseña incorrecto",
          });
        }
      );
    },
  },
};
</script>

<style scope>
.titleLogin {
  color: #25283b;
  font-family: "Nunito", sans-serif;
  font-size: 54px;
  margin: 0;
}

.subTitleLogin {
  color: #636b6f;
  padding: 0 25px;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.1rem;
  text-decoration: none;
  text-transform: uppercase;
}

.m-b-md {
  margin-bottom: 1px;
}
</style>
