var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticStyle:{"margin":"0"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',[_c('img',{staticStyle:{"margin-top":"5px"},attrs:{"src":"/logoapuntoga.png","alt":"Menu","height":"100px"}})])]),_c('v-col',{attrs:{"cols":"12"}},[[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"430","elevation":hover ? 8 : 2}},[_c('v-card-title',{staticStyle:{"background-color":"#fafafa"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_vm._v("Inicio de sesión")]),_c('v-list-item-subtitle',{staticClass:"subtitle-1"},[_c('small',[_vm._v("Ingrese sus credenciales")])])],1),_c('v-spacer'),_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-account")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12"}},[_c('v-text-field',{attrs:{"label":"Cuenta (*)","required":"","counter":30,"maxlength":"30","id":"email","rules":[
                            function (v) { return !!v || 'Cuenta es requerida'; },
                            function (v) { return (v && v.length <= 30 && v.length >= 5) ||
                              'La Cuenta debe contener al menos 5 caracteres'; } ]},model:{value:(_vm.usuarioDto.usuario),callback:function ($$v) {_vm.$set(_vm.usuarioDto, "usuario", $$v)},expression:"usuarioDto.usuario"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12"}},[_c('v-text-field',{attrs:{"id":"password","label":"Password (*)","append-icon":_vm.mostrarPass ? 'mdi-eye-off' : 'mdi-eye',"type":_vm.mostrarPass ? 'password' : 'text',"required":"","counter":30,"maxlength":"30","autocomplete":"off","rules":[
                            function (v) { return !!v || 'Password es requerido'; },
                            function (v) { return (v && v.length <= 30 && v.length >= 6) ||
                              'El Password debe ser de 6 a 30 caracteres'; } ]},on:{"click:append":function () { return (_vm.mostrarPass = !_vm.mostrarPass); }},model:{value:(_vm.usuarioDto.clave),callback:function ($$v) {_vm.$set(_vm.usuarioDto, "clave", $$v)},expression:"usuarioDto.clave"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"pl-4 pr-4",attrs:{"type":"submit","color":"primary"}},[_vm._v(" Ingresar "),(_vm.loading)?_c('v-progress-circular',{staticStyle:{"margin-left":"10px"},attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-login")])],1)],1),_c('br'),_c('v-divider'),_c('div',{staticClass:"pa-1 text-center"}),_c('v-card-text',{staticClass:"text-center text--white"},[_vm._v(" 2022 — "),_c('strong',[_vm._v("Apunto")]),_c('br')])],1)]}}])})],1)],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }